@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}

.content-section {
  animation: slideInRight 0.6s ease-out forwards;
  opacity: 0;
}

/* Prose styles for dark theme */
.prose-invert {
  color: #e5e7eb;
}

.prose-invert a {
  color: #60a5fa;
}

.prose-invert strong {
  color: #f3f4f6;
}

.prose-invert code {
  color: #f3f4f6;
  background-color: rgba(55, 65, 81, 0.5);
  padding: 0.2em 0.4em;
  border-radius: 0.25em;
}

.prose-invert pre {
  background-color: #1f2937;
  border-radius: 0.5rem;
}

.prose-invert blockquote {
  color: #9ca3af;
  border-left-color: #4b5563;
}

.prose-invert h1,
.prose-invert h2,
.prose-invert h3,
.prose-invert h4 {
  color: #f3f4f6;
}

.prose-invert hr {
  border-color: #374151;
}

.prose-invert ol > li::before {
  color: #9ca3af;
}

.prose-invert ul > li::before {
  background-color: #6b7280;
}

.prose-invert thead {
  color: #f3f4f6;
  border-bottom-color: #4b5563;
}

.prose-invert tbody tr {
  border-bottom-color: #374151;
}
