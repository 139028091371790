@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-grid-white {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1b2c49;
  color: white;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(39, 39, 99);
}

/* Handle on hover */

.react-dropdown-container {
  background-color: rgba(12, 25, 44, 0.8); /* bg-navy-900/80 */
  border-radius: 0.5rem;
  border: 1px solid #374151; /* border-gray-700 */
}

.react-dropdown-container .Dropdown-control {
  background-color: transparent;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
}

.react-dropdown-container .Dropdown-placeholder {
  color: #9ca3af; /* gray-400 */
}

.react-dropdown-container .Dropdown-arrow {
  border-color: #60a5fa transparent transparent; /* blue-400 */
}

.react-dropdown-container .Dropdown-menu {
  background-color: #1e293b; /* navy-800 */
  border: 1px solid #374151; /* border-gray-700 */
}

.react-dropdown-container .Dropdown-option {
  color: white;
  padding: 0.75rem 1rem;
}

.react-dropdown-container .Dropdown-option:hover {
  background-color: rgba(96, 165, 250, 0.1); /* blue-400/10 */
}

.react-dropdown-container .Dropdown-option.is-selected {
  background-color: rgba(96, 165, 250, 0.2); /* blue-400/20 */
}
